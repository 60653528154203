/*
    Created on : Sep 16, 2020, 10:59:06 AM
    Author     : vk
*/
/*HEADER*/
.containment {
    position: absolute;
    z-index: 999;
    height: 20px;
    left:  200px;
}
.companyLogo img{
    max-width: 100%;
    max-height: 50px;
}
.containment-old {
    display: none;
}
.header.fixed-top {
    width: 100%!important;
    position: fixed;
    top: 0;
    right: 0;
    align-items: center;
}
/*.header.fixed-top.collapsed{
    left:  80px;
    width: calc(100% - 80px)!important;
}*/

.add-fast-button {
    width: 22px;
    height: 22px;
    background: #ff3465;
    float: left;
    border-radius: 100%;
    text-align: center;
    line-height: 23px;
    font-size: 18px;
    color: white;
    margin-top: 0px;
}

.add-fast-button:hover .ion-android-add,
.add-fast-button .ion-chevron-down {
    display: none;
}
.add-fast-button:hover .ion-chevron-down {
    display: inline;
    color: white;
    font-size: 13px;
        cursor: pointer;
}

.header .ant-menu-horizontal .ant-menu-item i, .header .ant-menu-horizontal .submenu-title-wrapper i, .ant-menu-horizontal > .ant-menu-submenu{
    font-size: 24px;
    color: #fff;
}
.header .mobileLink{
    display: none;
}

/* SEARCH */
.globalSearch-box{
    margin: 5px 0px;
}
.globalSearch .ant-select-selection{
    height: 48px;
    color: black;
}
.globalSearch .ant-select-selection__rendered, .globalSearch .ant-select-search__field{
    line-height: 48px;
}
.globalSearch .ant-select-selection__placeholder{
    color: #7f7f7f;
}
.globalSearch .ant-select-search__field__mirror{
    padding-left: 20px;
}
.globalSearch-link{
    padding: 10px 130px 10px 10px;
}
.globalSearch-select{
    width: 100%;
}
.globalSearch-select .ant-select-selection.ant-select-selection--multiple{
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    font-size: 16px;
    color: #000;
}

.globalSearch-select .ant-select-selection.ant-select-selection--single{
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    min-height: 32px;
    font-size: 16px;
    color: #000;
}

.admin-menu li{
    padding-left: 25px;
}

.starter-plan:hover, .starter-plan.ant-menu-item-selected{
    border-bottom: 2px solid transparent!important;
}

.header .icon{
    float: right;
    border-right: 1px solid #fff;
    padding: 0 15px 0 0;
    line-height: 60px;
    height: 50px;
    color: #e3e3e3;
    font-size: 28px;
    margin: 0 15px 0 0;
}


/* NOTIFICATIONS */
.notification_menu, .help_menu{
    display: block;
    right: 43px;
    left: unset;
    padding: 0px;
    max-width: 450px;
    position: fixed;
    top:50px;
}
.notification-header{
    background-color: #f8f8f8;
    line-height: 65px;
}
.help-header{
    background-color: #f8f8f8;
    line-height: 35px;
    padding: 5px 10px;
}
.help_menu a{
    line-height: 40px!important;
}
.shortcuts{
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: 400;
    line-height: 1.42857143;
    color: #333;
    white-space: nowrap;
}
.user-header{
    line-height: 35px;
    padding: 5px 10px;
}
.username {
    font-weight: bold;
    text-transform: capitalize;
    padding-left: 10px;
    font-size: 16px;
}
.clear-all {
    margin-right: 15px;
}
.notif {
    padding-left: 20px;
    padding-right: 10px;
    line-height: 25px;
    padding-top: 10px;
    display: table;
    border-bottom: 1px solid #eee;
}
.notification_array .notif:hover {
    background: #f7f7f7;
}
.notif i {
   padding-right: 7px;
}
.notif i.unread {
    color: #ff6e2c;
}
.notif-i{
    display: flex;

}
.notif .notif-edit {
    cursor: pointer;
    display: flex;
    margin: 2px 0;
}
.notif-desc{
    width: 330px;
  }
.notif_date {
    font-size: 12px;
    color: #b9b9b9;
}

.header .ant-menu-horizontal{
    background: transparent;
}
@media only screen and ( min-width: 768px){
    .header .ant-menu-horizontal{
        border-bottom: none;
        justify-content: flex-end;
        padding-right: 20px;
    }
}

@media only screen and ( max-width: 767px) {
  .containment{
    position: relative;
    height: 93px;
    left: 0;
  }
  .wrapper{
    top:  0;
  }
  .header.fixed-top {
    width: 100%;
    position: fixed;
  }
  .companyLogo img, .add-fast-button {
    display: none;
  }
  header .notification_menu, header .help_menu{
    top: 125px;
    right: 0;
  }
  .globalSearch-mobile{
    margin-left: 40px;
  }

  #printer-link-new{
    display: none;
  }
  .header .ant-menu-horizontal .ant-menu-item i, .header .ant-menu-horizontal .submenu-title-wrapper i, .ant-menu-horizontal > .ant-menu-submenu{
        font-size: 22px;
   }
}
