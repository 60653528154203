body{
  background: inherit;
}
.tableCollection table {
    width: 100%;
}

.ant-picker.ant-picker-disabled {
  min-width: 120px;
  background: #ededed!important;
}

.popUpTable .css-1rtrksz {
    max-width: 110px;
}

.tableCollection {
    display: block;
    position: relative;
}

.tableCollection table > thead {
    border: 1px solid #ccc;
    background-color: #eaf0f5;
}

.tableCollection table > thead td {
    border: 0;
    padding: 10px 3px 0 3px !important;
    font-weight: bold;
}

.tableCollection table tbody td{
    border: 1px solid #ccc;
}
.tableCollection table tbody td .highlightField{
  background-color: #ff000047;
}
.tableCollection table tbody tr.isDuplicate td.rowNumber{
    background-color: #ff8d00 !important;
    padding: 10px !important;
    text-align: center;
}

.tableCollection table tbody td.rowNumber{
    background-color: #eaf0f5;
    padding: 10px !important;
    text-align: center;
}
.searchLine-quantity{
    min-width: 160px!important;
    line-height: 2.5;
}
.searchLine-quantity input[type='number']{
    min-width: 10px !important;
}

.form-treeview-quantity input[type='number']{
    min-width: 20px!important;
    width: 70px;
}

.tableCollection table td.left, .tableCollection table td.right {
    background-color: transparent !important;
    border: 1px solid transparent !important;
    width: 10px;
    text-align: center;
}

.tableCollection table td.left span i, .tableCollection table td.right span i {
        color: #8c9cb0;
        font-size: 16px;
}
.tableCollection table td.right span i:hover {
     color: red;
}
.tableCollection table td.right {
    border-left: 1px solid #ccc !important;
    padding-left: 10px;
}

.tableCollection table td.left {
    border-right: 1px solid #ccc !important;
    padding-right: 10px;
}

.tableCollection table td.tableHeader {
    background-color: #f0eae7 !important;
}

.tableCollection table td.tableHeader.popup {
    background-color: #ffffff !important;
}

.tableCollection table td.tableHeader.white {
    background-color: white !important;
}

.tableCollection table td i {
    cursor: pointer;
}

.tableCollection input[type='text'], .tableCollection input[type='number'] {
    margin: 0 !important;
    border-radius: 0;
    background-color: #fff;
    color: black;
}
.tableCollection input[type='number']  {
    padding: 0 !important;
}

.tableCollection .css-1aya2g8, .tableCollection .css-2o5izw {
    border-radius: 0!important;
    color: black;
}
.tableCollection .css-rsyb7x {
    min-width: 100px;
    color: black;
}
.tableCollection .css-10nd86i, .tableCollection .css-1sontr1 {
    min-width: 189px;
    color: black;
}

.tableCollection .css-1sontr1 .css-1492t68{
    display: none;
}
.input-group-addon {
    background-color: transparent!important;
}
.panel-heading input[type="text"]:focus  {
    border-right: 1px solid #44c699!important;
}
.percent-input {
    border-right: none!important;
}

.custom-table{
    overflow-x: auto;
    overflow-y: hidden;
}
table.mobileTable{
    border-radius: 4px;
    border: 1px solid #d3d3d3;
    box-shadow: 0 1px 1px #aaa;
    margin: 0;
  }
.mobileTable tbody:nth-child(2n+1){
    background: #eaf0f5;
}
.modal-header{
      z-index: 1050!important;
}
.mobileTable tbody{
    background: #f8f9fc;
}
.mobileTable tbody > tr{
    padding: 5px;
}
table.mobileTable .mobileTable-td{
    border: none;
    position: relative;
    white-space: normal;
    text-align: left;
}
.mobileTable-label{
  padding-right: 10px;
   white-space: nowrap;
   text-align: left;
   font-weight: bold;
   width: 100px;
}
.searchBar{
    display: flex;
    align-items: baseline;
}
.searchBar input [type="text"]{
  width: 400px;
  border-radius: 3px;
}
.rectangular_button{
  text-decoration: none;
  padding: 3px 23px 3px 23px;
  border-radius: 3px;
  background: white;
}
.rectangular_button:hover {
  border-bottom-width: 2px;
}
.form-input-field .ant-select-selection--single{
  border: 1px solid #d6d6d6!important;
  border-radius: 3px!important;
}
.form-input-field .ant-select-selection__rendered{
  line-height: 40px!important;
}
.orderItem-select .ant-select-arrow i {
    font-size: 14px;
    line-height: normal;
}
.orderItem-select span.ant-select-arrow{
    margin: -5px;
  }
/* TreeView */


.searchBar .button-primary{
  margin-top: 0px;
}
.table .ant-tree li .ant-tree-node-content-wrapper {
    white-space: normal;
    height: auto;
}
.table .ant-tree.ant-tree-directory > li span.ant-tree-node-content-wrapper.ant-tree-node-selected, .table .ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-node-content-wrapper.ant-tree-node-selected {
    white-space: inherit;
}

.ant-input-number .ant-input-number-input-wrap input{
  text-align: right;
  padding-right: 35px!important;
}

.ant-input-number .ant-input-number-handler-wrap {
  opacity: 1;
}

.tableCollection table td.borderNone.left{
    border-right: 1px solid #f0eae7 !important;
}
.tableCollection.tableOverflow{
  overflow-x: auto;
}

.tableCollection table td.borderNone.left.popup{
  border-right: 0px!important;
}

/* POS */

.pos-billing-data {
  background-color: white;
  margin-top: 0px;
  width: 100%;
  border: 1px solid #d3d3d3;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 1px rgba(0,0,0,.05);
  box-shadow: 0 1px 1px #aaa;
  margin-bottom: 10px;
  overflow: auto;
}
.pos-customer .panel.total{
    min-height: 100px!important;
    padding-bottom: 10px;
    margin: 0px;
    margin-bottom: 15px;
}
.pos-customer .panel-heading{
    padding: 8px 25px;
}
.pos-customer .col-md-12 {
    padding-right: 10px;
}
.pos-customer .panel.total .form-group label{
  display: none;
}
.pos-billing-data .posTable{
    margin-bottom: 10px;
    width: 100%;
}
.pos-billing-data .posTable thead, .pos-billing-data .posTable tbody tr{
    border-bottom: 1px solid #cccccc;
}
.pos-billing-data .totals tr.line{
   border-top: 1px solid #cccccc;
}
.pos-billing-data .posTable td, .pos-billing-data .totals td{
    width: 100%;
    padding: 3px 5px;
}
.pos-billing-data .totals td{
    width: 80%;
}
 .pos-billing-data .totals td{
   padding-left: 10px;
   padding-right: 10px;
 }
.pos-billing-data .posTable tbody input{
    width: 100px;
    border: 1px solid #d6d6d6;
    font-weight: 600;
    text-decoration: underline;
    font-size: 15px;
    padding: 0px;
    min-width: 100px!important;
    margin-bottom: 0px;
}
.pos-billing-data .posTable thead td{
    min-width: 100px!important;
    text-align: center;
}

.pos-billing-data .posTable input[type='text']{
    width: 100%;
    min-width: 100px!important;
}
.pos-billing-data .posTable input[disabled]{
    background-color: inherit!important;
    text-decoration: none;
    border: none;
}

.pos-billing-data .totals{
    width: 100%;
}
.pos-billing-data .totals .value{
   padding-right: 50px;
}
.pos-billing-data .totals .value h4{
    float: right;
}
.pos-button .button-primary {
    width: 250px;
    height: 50px;
    font-size: 16px;
    font-weight: 600;
}
.pos-billing-data .posTable input[type='text'] {
  min-width: 100px!important;
}
.posCategory{
    margin-bottom: 5px;
}
.posCategory .ant-card:hover{
  border-top: 1px solid #44c699;
}
.posCategory-card{
   text-align: center;
   height: 160px;
   margin-right: 5px!important;
   align-items: center;
   display: grid;
}
.posCategory-sku{
  position: absolute;
  top: 3px;
  right: 7px;
  font-size: 15px;
}
.posCategory-price{
  font-size: 15px;
}
.posCategory-img{
    margin: 0 auto;
    max-width: 60px;
    margin-top: 10px;
}
.posCategory .ant-card-body{
    padding: 5px;
}
.pos.breadcrumbs{
    align-items: center;
    background-color: #d9e0ef;
    margin-bottom: 20px;
    margin-top: 20px;
    margin-left: 60px;
    height: 40px;
    display: grid;
    color: rgba(0, 0, 0, 0.65);
    padding: 0 10px;
  }
.posPage .search #search{
  padding-left: 30px!important;
}
.pos.breadcrumbs .ant-breadcrumb-link{
    cursor: pointer;
}
.pos.breadcrumbs .ant-breadcrumb-separator{
    margin: 0 5px;
  }
.left-mobileMenu{
   position: absolute;
   top: 6px;
   left: 0px;
   z-index: 1000;
}
.left-mobileMenu i{
    color: white;
    font-size: 30px;
    margin-left: 5px;
}
.left-menuPos{
   background-color: #f0eae7;
   position: absolute;
   top: 24px;
   left: 16px;
   z-index: 1000;
}
.list-tableLink {
    cursor: pointer;
    text-decoration: none;
}
.list-tableRow .top {
    font-weight: 600;
}
.list-tableRow .first {
    padding-right: 8px;
    border-right: 1px solid grey;
}
.list-tableRow .second {
    padding-left: 8px;
}
.list-tableRow .list-date, .list-tableRow .list-stage {
    color: #879697;
}
#pos-content .dashboard .moduleSubMenu h3, #pos-content .moduleSubMenu h3 {
    padding-left: 80px;
}
#makePayment .padding10{
  padding: 0px 10px!important;
}
.tableCollection .ant-select-disable .ant-select-selector{
    background: transparent;
}
.tableCollection .ant-select-disabled .ant-select-selection-item{
    color: #000;
}
label{
    font-size: 16px;
    color: inherit;
}
.table-inbox{
    font-size: 15px;
}
.heightAuto .css-1tkhqcq{
  height: auto;
}
.col-centered{
    float: none;
    margin: 0 auto;
}
.printDatasource{
    background-color: white;
    margin: auto;
    height: 1123px;
    max-width: 864px;
    margin-bottom: 50px;
    border-radius: 2px;
    box-shadow: 0 0 7px 0 #d4d4d4;
    overflow: auto;
    padding: 40px;
}

.dashboard-spinner{
  position: absolute;
  display: flex;
  top: 0;
  width: 100%;
  padding: 15px;
  height: 100%;
  background-color: #fbfafa;
  opacity: 1;
  z-index: 10;
}

.ant-drawer-wrapper-body {
  height: 90%!important;
}
.ant-drawer-content {
  background-color: #f0eae7!important;
}


    /* Mobile*/

@media only screen and (max-width: 760px){
  .pos-categories{
    display: none;
  }
  .pos-customer .row.m-bot15 {
      margin: 0px;
  }
  .posPage .col-xs-12{
    padding-right: 15px;
  }
  .panel.total p.total {
    font-size: 17px;
  }
}

@media only screen and (max-width: 780px) {
    .custom-table{
        overflow-x: visible;
        overflow-y: visible;
    }
}

@media only screen and (max-width: 795px){
  .posCategor-card{
    margin-right: 0px;
  }
  .posCategory-img{
    max-width: 40px;
  }
  .pos-billing-data .posTable td{
    padding: 0px;
  }
  .pos-billing-data .posTable thead, .tableCollection table thead, .searchItem-table thead{
      display: none;
  }
  .pos-billing-data .posTable tbody td:before, .tableCollection table tbody td:before, .searchItem-table tbody td:before{
    content: attr(data-title);
  }
  .pos-billing-data .posTable tbody td:before, .tableCollection table tbody td:before, .searchItem-table tbody td:before{
      position: absolute;
      top: 6px;
      left: 10px;
      width: 45%;
      padding-right: 10px;
      white-space: nowrap;
      text-align: left;
  }
    .tableCollection, .searchItem-table {
      width: 100%!important;
  }
  .tableCollection table tbody td:before, .searchItem-table tbody td:before{
      font-weight: bold;
      font-size: 14px;
  }
  .searchBar{
    display: grid;
    margin-bottom: 10px;
  }
  .searchBar .button-primary{
    margin-top: 10px;
  }
  .tableCollection input[type='number'], .searchItem-table input[type='number']{
    width: 70%;
    padding-right: 10px!important;
  }
  .searchBar input [type="text"]{
    width: 100%;
  }
  .searchBar input {
    width: auto;
  }
  .pos-billing-data .posTable tbody tr, .tableCollection table tbody tr, .searchItem-table tbody tr{
      display: block;
      background: white;
  }
  .pos-billing-data .posTable tbody tr:nth-child(2n+1) {
      background: #f8f9fc;
  }
  .tableCollection table tbody tr:nth-child(2n+1), .searchItem-table tbody tr:nth-child(2n+1){
      background: #eaf0f5;
  }
  .pos-billing-data .posTable tbody td, .tableCollection table td, .searchItem-table td{
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50%!important;
    white-space: normal;
    text-align: left;
    min-height: 40px;
    display: block;
  }
  .pos-billing-data .totals td {
    width: auto;
  }
  .tableCollection table td, .searchItem-table tbody td{
    border-bottom: none!important;
  }
  .searchItem-table tbody td {
    border: 1px solid #ccc;
    border-bottom: none;
    }
  .tableCollection table tr, .searchItem-table tbody tr{
   border-bottom: 1px solid #ccc;
  }
  .pos-billing-data .totals td{
    padding: 0px 10px;
  }
  .printDatasource{
    padding: 0px;
  }
}

@media only screen and (max-width: 1200px){
  .pos-billing-data .posTable input[type='text']{
    min-width: 100px!important;
  }
  .pos-billing-data .posTable thead td, .pos-billing-data .posTable tbody input{
    min-width: 50px!important;
  }
  .pos-billing-data .posTable input{
      height: 30px;
      width: 100%!important;
      font-size: 13px!important;
      padding: 7px!important;
      text-align: left;
  }
  .posPage .panel-heading span {
    font-size: 13px;    margin-bottom: 10px;
  }
 .slide-modal .modalConfirms{
    width: 95%;
  }
  .add-customer-label>i {
    font-size: 16px;
    margin: 0 3px 0 0;
  }
  .add-customer-label>p{
    font-size: 13px;
  }
  .tableCollection{
    width: 100%!important;
  }
  .searchBar span{
    display: flex;
  }

/*  @media only screen and (max-width: 1300px){
    .tableCollection .ant-select{
        width: 100%;
    }
  }*/
}
