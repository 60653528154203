.gridV2 .grid-content .ant-table,
.gridV2 .grid-content .ant-table .ant-table-thead,
.gridV2 .grid-content .ant-table .ant-table-tbody > tr > td > a,
.gridV2 .grid-content .ant-table .ant-table-tbody .table-actions a,
.gridV2 .grid-content .ant-table .ant-table-tbody > tr > td > span,
.gridV2 .grid-content .ant-table > a,
.gridV2 .custom-filters,
.custom-filters-container .custom-filters,
.gridV2 .custom-filters-table .ant-table .ant-table-tbody > tr > td > span,
.gridV2 .bulk-actions{
  font-size: 16px;
  color: #000!important;
}
.gridV2 .grid-content .ant-table .ant-table-thead > tr > th{
  font-size: 16px;
  color: #0000008a;
  font-weight: 600;
}
.custom-filters-container .custom-filters{
  height: 36px;
}
.gridV2 .grid-datepciker .ant-picker{
  width: 80%;
}
.gridV2 .grid-icon{
  cursor: pointer;
  color: #454545;
}
.gridV2 .custom-filter-selected{
  border: 2px solid #38b88c;
}
.gridV2 .moduleSubMenu .grid-button-new .button-title{
  vertical-align: text-top;
}
.bulk-actions-options {
  border: 1px solid #e5dede;
  box-shadow: 1px 1px #ccc;
}
.grid-search-field input,
.mobile-search input{
	height: 28px;
	margin-bottom: 0;
}
.ant-table-body {
  min-height: 500px;
}
.demo-infinite-container {
  height: 300px;
  padding: 8px 24px;
  overflow: auto;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  background-color: white;
}
.demo-loading-container {
  position: absolute;
  bottom: 40px;
  width: 100%;
  text-align: center;
}
.grid-custom-filter-row .only-button{
  display: none;
}
.grid-popup-form{
  top: 50px;
}

.grid-popup-form .ant-drawer-body{
  background-color: #f0eae7;
}

.grid-button-new{
  background-color: #44c699;
  height: 35px;
  min-width: 125px;
  border-radius: 3px;
  text-align: center;
  border: none;
  color: white;
  margin-right: 10px;
  text-transform: uppercase;
}
.grid-button-new:hover,
.grid-button-new:active,
.grid-button-new:focus{
  background-color: #38b88c;
  color:  white;
}
.grid-total-records{
  position: relative;
  bottom: 40px;
  float: left;
  padding-left: 40px;
  font-size: 16px;
}

.grid-popup-form .ant-drawer-close{
  position: absolute;
  right: 0;
}
.grid-popup-form .dashboard{
  display: none;
}
.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging td {
  padding: 16px;
}

.row-dragging .drag-visible {
  visibility: visible;
}

.grid-popup-form .panel.total{
  min-height: 200px;
}

.custom-filters-container{
  margin-right: 10px;
}


.mobileFiltersGridBtn{
    margin-left: 10px; 
    width: 90%; 
    margin-right: 10px;
}

.mobileFiltesGridFonts span{
    color: rgba(0,0,0)!important;
    font-size: 16px;
}

.gridHeader-tooltip{
  max-width: 280px;
}

.grid-save-view-drawer .ant-drawer-content,
.grid-columns-drawer .ant-drawer-content {
  background-color: white!important;
}
@media (max-width: 310px) {
  .grid-popup-form .button-outline.red{
    min-height: 80px;
  }
}
@media only screen and (min-width: 767px){
  .demo-infinite-container{
    width: 350px;
  }
  .grid-save-view-drawer .content{
    display: flex;
    align-items: center;
  }
  .grid-save-view-drawer .isPublic{
    padding-top: 17px;
  }
  .grid-custom-filter-row:hover .only-button{
    display: block;
  }
}
@media only screen and (max-width: 767px){
 .gridV2 .grid-button-new{
      min-width: 56px;
      width: 56px;
      height: 56px;
      position: fixed;
      bottom: 100px;
      right: 20px;
      z-index: 99;
      border-radius: 50%;
      font-size: 28px;
      box-shadow: 0 2px 2.5px 0 rgb(0 0 0 / 12%), 0 2px 2px 0 rgb(0 0 0 / 24%);
  }
  .gridV2 .grid-search-field{
    display: none;
  }
  .gridV2 .grid-datepciker{
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
  }

  .gridV2 .mobile-search{
    width: 335px;
    position: absolute;
    top: 40px;
    z-index: 1;
    border: 1px solid #c3c3c3;
  }

  .mobile-search input{
    height: 45px;
  }

  .custom-filters-container{
    display: flex;
    flex-direction: column;
  }

  .gridV2 .ant-table-measure-row{
    visibility: collapse;
  }

  .grid-columns-drawer .ant-drawer-body,
  .grid-save-view-drawer .ant-drawer-body{
    padding: 8px;
  }

  .grid-save-view-drawer .isPublic{
    padding-top: 37px;
  }

  .gridHeader-tooltip{
    max-width: 200px;
  }

  .ant-picker-panels{
    flex-direction: column;
  }

}