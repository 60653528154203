.invoice-title {
  width: 48%;
  padding: 0;
  text-align: center;
}

.printData {
  background-color: white;
  margin: auto;
  border-radius: 2px;
  padding: 40px;
}

/* body {
    background-color: #f2f6ff;
    position: relative;
    overflow-x: hidden;
    font-family: "ProximaNovaA",sans-serif;
} */

.navbar {
  background-color: white;
}
.navbar-right {
  display: flex;
  align-items: center;
  margin-right: 125px;
  margin-top: 12px;
}
.navbar-right .icon-wrapper {
  display: flex;
  margin-right: 30px;
}
.navbar-right .language {
  display: flex;
  align-items: center;
}
.navbar-right .language span {
  color: black;
}
.navbar-right .language:hover {
  text-decoration: none;
}
.navbar-right .language .icon-wrapper {
  display: flex;
  margin-right: 5px;
}
.container {
  width: 1420px;
  max-width: 1480px;
  padding-top: 50px;
  padding-bottom: 30px;
  padding-left: 0;
  min-height: calc(100vh - 50px);
}
.container .breadcrumb {
  background-color: transparent;
  padding-left: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  max-width: 100vw;
}
.container .breadcrumb-item a {
  color: #212529;
  font-size: 16px;
}
.container .breadcrumb-item.active {
  color: black;
}

.breadcrumb > li + li:before {
  padding: 0 3px;
}
.container .breadcrumb > li {
  font-size: 16px;
}

.card {
  border-radius: 16px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  margin-bottom: 20px;
}
.card-body {
  padding: 30px;
  background-color: white;
  border-radius: 24px;
}
.card .card-price {
  background-color: #f5f5f5;
  padding: 10px;
  border-radius: 6px;
  margin-bottom: 15px;
}

.card-price--info {
  background-color: white;
}

.card .lead {
  font-size: 16px;
  font-weight: bold;
}
.card .card-price .total {
  font-size: 20px;
  display: flex;
  align-items: baseline;
}
.card .card-price .total .curency {
  font-size: 17px;
  margin-left: 5px;
}

.card .card-price .total .curency-sign {
  margin-left: 5px;
}

.card .card-price--info {
  display: flex;
}
.card .card-price--info .lead {
  margin-bottom: 0;
}
.card .card-price--info .status,
.card .card-price--info .due-date {
  width: 50%;
}
.card .payment-history {
  display: flex;
}
.card .payment-history .date {
  width: 65%;
  font-size: 16px;
  font-weight: bold;
}
.card .payment-history .payment-info .curency {
  font-size: 12px;
}

.buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}
.btn-download {
  padding: 5px 30px;
  font-size: 24px;
  background-color: white;
  color: #44c699;
  border-radius: 30px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  /* width: 40%; */
  font-weight: bold;
}
.btn-download:hover {
  color: white;
  background-color: #44c699;
}
.btn-pay:hover {
  background-color: white;
  color: #44c699;
}
.btn-pay {
  padding: 5px 67px;
  font-size: 24px;
  color: white;
  background-color: #44c699;
  border-radius: 30px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  /* width: 40%; */
  font-weight: bold;
}
.software {
  margin-top: 55px;
  font-size: 16px;
  text-align: center;
}
.bg-icon-wrapper-left {
  position: absolute;
  bottom: 0;
  left: -300px;
}
.bg-icon-wrapper-right {
  position: absolute;
  bottom: 80px;
  right: 0;
}

@media print {
  body {
    -webkit-print-color-adjust: exact;
  }
}

#printDoc.ant-menu-item.ant-menu-item-selected.ant-menu-item-only-child {
  background-color: #323639 !important;
}

.mobile-text {
  max-width: 100vw;
  display: none;
  padding: 20px 30px 20px 20px;
  text-align: center;
  font-size: 20px;
}

@media (max-width: 768px) {
  .mobile-embed {
    padding: 0px 20px;
    max-width: 100vw;
    display: none;
  }

  .mobile-text {
    display: block;
  }
  .card-body {
    padding: 5px;
  }
  .card {
    width: 100%;
  }
  .mobile-container {
    padding: 0px 20px;
    max-width: 100vw;
    position: relative;
    z-index: 10;
  }
  .justify-content-center {
    width: 100vw;
    z-index: 10;
    position: absolute;
    bottom: 50px;
  }

  .mobile-skeleton {
    display: none;
  }
  .card-price--info {
    padding-left: 10px;
  }
}
